@import url('https://fonts.googleapis.com/css?family=Courgette');
@import url('https://fonts.googleapis.com/css2?family=Shrikhand&display=swap');

@font-face {
    font-family: Bright;
    src: local('Bright'), url(../../public/fonts/Bright.otf) format('otf');
  }

  
video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}


.main-container > p {
    margin-top: 8px;
    color: #ffffff;
    font-size: 32px;
}

.main-btns {
    margin-top: 32px;
    margin-bottom: 32px;
}

.main-btns .btn {
    margin: 6px;
}

.responsive-type-animation {
    font-size: 12vw;
    text-align: center;
}
.title {
    font-family: 'Montserrat';
}

Button {
    font-family: 'Montserrat';

}
.responsive-type-animation {
    vertical-align: middle;
}

/* iPhone Screen */
@media screen and (max-width: 720px) {
    .responsive-type-animation {
        font-size: 15vw;
    }
}

/* Macbook Screen */
@media screen and (max-height: 720px) {
    .main-container {
        min-height: 54rem;
      }
}

/* Mac Screen */ 
@media screen and (min-width: 1440px) and (max-height: 1063px) {
    .responsive-type-animation {
        font-size: 172.8px;
    }
    
}