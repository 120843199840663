.footer-container {
    background-color: #000;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.social-media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.social-logo {
    color: #ffffff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-self: center;
    margin-bottom: 16px;
}

.logo {
    border-radius: 100px;
}