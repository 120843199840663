@import url('https://fonts.googleapis.com/css?family=Courgette');
@import url('https://fonts.googleapis.com/css2?family=Shrikhand&display=swap');

@font-face {
  font-family: Bright;
  src: url(/public/fonts/Bright.otf);
}

.navbar {
  font-size: 1.2rem;
}

.navbar-container {
  display: inherit;
  background-color: #000000;
  position: fixed;
  width: 100%;
  min-height: 75px;
  max-height: 75px;
  z-index: 1;
}

.logo-name {
  font-family: 'Bright';
  text-shadow: 3px 2px #f67c90;
  color: #fad1d6;
  font-size: 60px;
  text-decoration: none;
  margin-left: 2vw;
  margin-top: 17px;
  float: left;
}

.menu-icon {
  display: none;
  color: #ffffff;
}

.nav-menu {
  display: inline-flex;
  float: right;
  margin-right: 20px;
}

.active {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
  margin-bottom: -1vh;
}

.nav-item {
  min-height: 75px;
  max-height: 75px;
  display: inline;
}

.nav-links {
  color: #f6c4ca;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: 'Montserrat';
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.nav-links-mobile {
  display: none;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.fa-bars {
  color: #fff;
}

/* Macbook */
@media screen and (max-width: 1025px) and (max-height: 714px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: #635f5f;
  }

  .nav-menu.active {
    background: #242222;
    top: 4.8vw;
    height: fit-content;
    left: 0;
    z-index: 1;
  }

  .nav-item {
    margin-bottom: 25px;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
    padding-top: 30px;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }
  
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 3vh;
    cursor: pointer;
    margin-top: 16px;
  }
} 

/* Mac */
@media screen and (max-width: 1047px) and (min-height: 720px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: #635f5f;
  }

  .nav-menu.active {
    background: #242222;
    top: 4.8vw;
    height: fit-content;
    left: 0;
    z-index: 1;
  }

  .nav-item {
    margin-bottom: 25px;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
    padding-top: 30px;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 3vh;
    cursor: pointer;
  }
}

/* iPhone Screen  and laptop */
@media screen and (max-width: 720px) and (max-height: 714px) {
  .menu-icon {
    font-size: 3vh;
  }

} 
/* Macbook */
@media screen and (max-width: 650px) and (max-height: 714px) {
  .menu-icon {
    font-size: 3vh;
    padding-bottom: -3vw;
  }
  
}